import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { ILastViewedProductsProps, LastViewedProductsWrapper } from '~/components/core/lastViewedProducts'
import { ProductsCarousel } from '~/components/core/productsCarousel'
import { SectionHeader } from '~/components/core/sectionHeader'
import { IRootState } from '~/state/types'

const LastViewedProducts = (props: ILastViewedProductsProps): ReactElement | null => {
	const {
		dataTestId = '',
	} = props
	const { products } = useSelector((state: IRootState) => state.lastViewed, shallowEqual)
	const { t } = useTranslation(['common'])

	if (isEmpty(products)) {
		return null
	}

	return (
		<LastViewedProductsWrapper dataTestId={ dataTestId }>
			<SectionHeader title={ t('lastViewed.caption') } />

			<ProductsCarousel products={ products } />
		</LastViewedProductsWrapper>
	)
}

export { LastViewedProducts }
